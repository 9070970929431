import { graphql } from 'gatsby'
import React from 'react'
import get from 'lodash/get'
import Img from 'gatsby-image'

import SEO from '../components/seo'
import Layout from 'components/layout'

import '../scss/style.scss'

const HeaderLineIndex = () => {
  return (
    <div class="header bg-white">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <p class="display-4 pagetitle">Mental Health</p>
          </div>
        </div>
      </div>
    </div>
  )
}

const PictureLine = data => {
  return (
    <div class="header bg-white">
      <div class="container">
        <div class="row my-5">
          <div class="col-md-6">
            <div class="devices pl-md-4 pt-5 pt-md-0 pr-3">
              <Img
                fluid={data.imageOne.childImageSharp.fluid}
                alt="Mental health page image"
                className="rounded shadow-lg"
              />
            </div>
          </div>
          <div class="col-md-6 my-5">
            <p>
              Mental health includes you emotional, psychological, and social
              well-being. This impacts your thinking, feelings and behaviour and
              is important at every stage of life, from childhood through to
              adulthood.
            </p>
            <p>
              Through coaching techniques and skills, you are guided in making
              life changes and working towards achieving your personal goals.{' '}
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

const Description = () => {
  return (
    <div class="container pl-5 my-5">
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching will guide you in personal development by seeing the bigger
            picture.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Through coaching you will lean to understand your feelings and make
            better decisions.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching can guide you in dealing with a traumatic event that you
            have been through whereby you feel afraid and lack control.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Through coaching techniques, you can be guided in dealing with your
            phobias, these may include simple phobias or even social phobias
            where you fear being judged by others.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching a person dealing with depression and anxiety is quite
            common, whereby depression is a result of a past experience and
            anxiety the future, the aspect of not knowing.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching guides you in dealing with mental health effects like daily
            life, relationships, your ability to enjoy life as well as physical
            health
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching guides you in loving, appreciating and respecting yourself
            using techniques to deal with the fears and stress as a cause of
            anxiety and depression.
          </span>
        </div>
      </div>
      <div class="col-12">
        <div class="row">
          <span class="fa-li">
            <i class="fa fa-check text-primary mx-2"></i>
          </span>
          <span>
            Coaching will guide students in dealing with conflict, low
            self-esteem, social avoidance and learning issues.
          </span>
        </div>
      </div>
    </div>
  )
}

export default ({ data, location }) => (
  <Layout location={location} crumbLabel="Mental Health">
    <SEO site={get(data, 'site.meta')} />
    {HeaderLineIndex()}
    {PictureLine(data)}
    {Description()}
  </Layout>
)

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "health-3438514_1920.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
